<div class="facutly">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="fhead">
                    <h1>Our Faculty</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="faclty-list">
                   
                        <div class="faclty-box1">
                            <img src="assets/images/faculty/Dr.PradheepkumarC.jpg" alt="">
                            <p>
                                Dr. Pradeep Kumar C <br /> Director <br /> MBA Ph.D
                            </p>
                        </div>
                        
                    <div class="row"> 
                           
                        <div class="col-md-4">
                                <div class="faclty-box">
                                    <img src="assets/images/faculty/Dr.NeenuWilson.jpg" alt="">
                                    <p>
                                        Dr. Neenu Wilson <br /> Associate Professor  <br /> HOD MBA
                                    </p>
                                </div>
                        </div>  <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SreejithSukumaranAssistantProfessor.jpg" alt="">
                                <p>
                                    Sreejith Sukumaran <br /> Assistant Professor
                                </p>
                            </div>
                        </div> 
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SeenuBNairAssistantprofessor.jpg" alt="">
                                <p>
                                    Seenu B Nair <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Dr.FreedaVSimon.jpg" alt="">
                                <p>
                                    Dr. Freeda V Simon <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Ms.Elizabeth.jpg" alt="">
                                <p>
                                    Ms. Elizabeth <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Ms.SurabhiG.jpg" alt="">
                                <p>
                                    Ms. Surabhi G <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Ms.ThaslimaKS.png" alt="">
                                <p>
                                    Ms. Thaslima K S <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Vishnu.jpg" alt="">
                                <p>
                                    Mr. Vishnu K Ravi <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/AshokShankar.png" alt="">
                                <p>
                                    Mr. Ashok Shankar <br /> Corporate Trainer
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/AnnMaryFrancis.png" alt="">
                                <p>
                                    Ms. Ann Mary Francis <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="fhead">
                                    <h1>Guest Faculty</h1>
                                </div>
                            </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Mr.Nandkumar.jpg" alt="">
                                <p>
                                    Mr. Nandkumar <br /> Adjunct Faculty
                                </p>
                            </div>
                        </div>  
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/KrishnanS.png" alt="">
                                <p>
                                    Prof. Krishnan S  <br /> Adjunct Faculty
                                </p>
                            </div>
                        </div>  
                           
                    
                            
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>